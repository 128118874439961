import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/for-mf.jpeg";
import TopBackgroundMobile from "../images/headersMobile/3-for-mf-mobile.jpg";
import Slide1 from "../images/slides/mf/company-profile-eastman.png";
import Slide2 from "../images/slides/mf/showroom-fabric.png";
import Slide3 from "../images/slides/mf/catalogue-apparel.png";
import Slide4 from "../images/slides/mf/apparel-mf-search-for-buyers-1.png";
import Slide5 from "../images/slides/mf/contact-buyer-message-preview.png";
import Slide6 from "../images/slides/mf/fabric-mf-search-for-buyers.png";
import Slide7 from "../images/slides/mf/rfq-fabrics.png";
import Slide8 from "../images/slides/mf/rfq-apparel.png";
import Slide9 from "../images/slides/mf/business-opportunities-page.png";
import Slide10 from "../images/slides/mf/apparel-website.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("apparel-manufacturers.topPretitle"),
        colorClass: "colorDark",
        extraClass: "font24 fontWeight400 ",
      },
      subtitle: {
        text: t("apparel-manufacturers.topTitle"),
        colorClass: "colorDark",
        extraClass: "font50 fontWeight600 ",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackground,
        alt: t("apparel-manufacturers.imgAlt"),
      },
      cta: {
        text: t("meta.demoRequestText"),
        url: t("meta.demoDeskLink"),
        type: "infoColor",
        extraClass: "bigWidth",
      },
    },
    topInfoMobile: {
      title: {
        text: t("apparel-manufacturers.topPretitle"),
        colorClass: "colorDark",
        extraClass: "font24 fontWeight400 shadowEffect",
      },
      subtitle: {
        text: t("apparel-manufacturers.topTitle"),
        colorClass: "colorDark",
        extraClass: "font50 fontWeight600 shadowEffect",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackgroundMobile,
        alt: t("apparel-manufacturers.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("apparel-manufacturers.showcaseCompany"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("apparel-manufacturers.bePart"),
              t("apparel-manufacturers.createProfile"),
              t("apparel-manufacturers.exhibitProduct"),
              t("apparel-manufacturers.buildTrust"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2, Slide3],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("apparel-manufacturers.findBuyers"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("apparel-manufacturers.accessDatabase"),
              t("apparel-manufacturers.findFilters"),
              t("apparel-manufacturers.contactBuyers"),
              t("apparel-manufacturers.seeBuyers"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide4, Slide5, Slide6],
        },
        isTextFirst: false,
      },
      {
        info: {
          title: {
            text: t("apparel-manufacturers.spotOpportunities"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("apparel-manufacturers.beRFQ"),
              t("apparel-manufacturers.sendQuotes"),
              t("apparel-manufacturers.discoverRequests"),
              t("apparel-manufacturers.createOpportunities"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide7, Slide8, Slide9],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("apparel-manufacturers.enhanceMarketing"),
            colorClass: "colorDark",
          },
          icon: {
            fontAwesome: false,
            src: CheckIcon,
            alt: t("homepage.checkAlt"),
          },
          separator: true,
          item: {
            text: [
              t("apparel-manufacturers.convertProfile"),
              t("apparel-manufacturers.rankSEO"),
              t("apparel-manufacturers.manageVisibility"),
              t("apparel-manufacturers.shareLink"),
            ],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide10],
        },
        isTextFirst: false,
        cta: {
          text: t("apparel-manufacturers.joinFree"),
          url:
           process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],
  };
};
